import React from 'react'
import { Button, message } from 'antd'
import { useUser } from '../../services/User'
import { useTelegram } from '../../hooks/useTelegram'

import './WelcomePage.scss'
import LogoWelcome from '../../assets/images/welcome.png'

export default function WelcomePage() {
    const [messageApi, contextHolder] = message.useMessage()
    const { user } = useTelegram()

    const { useCheckSubscription } = useUser()
    const userMutation = useCheckSubscription()
    const channelurl = process.env.REACT_APP_CHANNEL_NAME

    const openTelegramChannel = () => {
        window.open(`https://t.me/${channelurl}`, '_blank')
    }

    const handleCheckSubscribtion = () => {
        userMutation.mutate(user?.id, {
            onSuccess: (res) => {
                if (res?.isSubscribed) {
                    localStorage.setItem('is_user_subscribed', '1')
                    window.location.reload()
                } else {
                    messageApi.open({
                        type: 'error',
                        content:
                            'Вы еще не подписаны, пожалуйста подпишитесь на наш канал!',
                    })
                }
            },
        })
    }

    return (
        <div className="WelcomePage">
            {contextHolder}
            <div className="container">
                <div className="WelcomePage-wrapper">
                    <div className="WelcomePage-wrapper-head">
                        <img src={LogoWelcome} alt="Welcome" />
                    </div>
                    <div className="WelcomePage-wrapper-title">
                        <h4>Earn 15% on savings!</h4>
                        <p>
                            Buy and earn 15% annually with USD-backed
                            stablecoins. Get paid daily with no hidden fees!
                        </p>
                    </div>
                    <div className="WelcomePage-wrapper-action">
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => openTelegramChannel()}
                            block
                        >
                            Подписаться
                        </Button>
                        <Button
                            onClick={() => handleCheckSubscribtion()}
                            size="large"
                            loading={userMutation.isLoading}
                            block
                        >
                            Проверить
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
