import { useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'

import cardApi from './cardApi'
import { CARD_KEY } from './cardConstants'

const useCard = () =>
    useMemo(
        () => ({
            useGetCards: (cardType, chatId) =>
                useQuery([CARD_KEY.getCards, cardType], () =>
                    cardApi.getCards(cardType, chatId)
                ),
            useUserGetCards: (chatId) =>
                useQuery([CARD_KEY.getCards, chatId], () =>
                    cardApi.getUserCards(chatId)
                ),
            useCreateApplication: () =>
                useMutation(({ fullname, cardId, chatId }) =>
                    cardApi.createApplication({ fullname, cardId, chatId })
                ),
            usePinCard: () => useMutation((params) => cardApi.pinCard(params)),
        }),
        []
    )

export default useCard
