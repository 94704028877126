import React from 'react'
import { PATHS } from './constants'

const HomePage = React.lazy(() => import('../pages/HomePage'))
const WelcomePage = React.lazy(() => import('../pages/WelcomePage'))
const ProfilePage = React.lazy(() => import('../pages/ProfilePage'))
const UserCardsPage = React.lazy(() => import('../pages/UserCardsPage'))
const BonusCardsPage = React.lazy(() => import('../pages/BonusCardsPage'))
const TelegramChannelRedirectPage = React.lazy(() =>
    import('../pages/TelegramChannelRedirectPage')
)

const ROUTES = [
    {
        path: PATHS.main,
        component: HomePage,
        active: false,
        name: 'main',
    },
    {
        path: PATHS.welcome,
        component: WelcomePage,
        active: true,
        name: 'subscribe_page',
    },
    {
        path: PATHS.profile,
        component: ProfilePage,
        active: true,
        name: 'profile',
    },
    {
        path: PATHS.userCards,
        component: UserCardsPage,
        active: true,
        name: 'userCards',
    },
    {
        path: PATHS.bonusCards,
        component: BonusCardsPage,
        active: true,
        name: 'bonusCards',
    },
    {
        path: PATHS.tgredirect,
        component: TelegramChannelRedirectPage,
        active: true,
        name: 'tgredirect',
    },
]

export const getRoutes = (user) => {
    if (user.isSubscribed === false) {
        localStorage.removeItem('is_user_subscribed')
    }
    const isSubscribed = localStorage.getItem('is_user_subscribed') || false
    if (isSubscribed) {
        return ROUTES.filter((route) => route.name !== 'subscribe_page')
    }
    return ROUTES.filter((route) => route.name === 'subscribe_page')
}

export default ROUTES
