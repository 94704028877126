import axios from '../../utils/axios'
import { CARD_KEY } from './cardConstants'

const API_KEY = process.env.REACT_APP_API_KEY
const LOCAL_CHAT_ID = localStorage.getItem('jovid_chat_id')

const cardApi = {
    async getCards(cardType, chatId) {
        const { data } = await axios.get(
            `${CARD_KEY.getCards}/?cardType=${cardType}&chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`
        )

        return data
    },

    async getUserCards(chatId) {
        const { data } = await axios.get(
            `${CARD_KEY.getUserCards}/?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`
        )

        return data
    },

    async createApplication({ fullname, cardId, chatId }) {
        const { data } = await axios.post(
            `${CARD_KEY.createApplication}/?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`,
            {
                fullname,
                cardId,
            }
        )

        return data
    },

    async pinCard({ cardId, chatId }) {
        const { data } = await axios.post(
            `${CARD_KEY.userPinCard}/?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`,
            {
                cardId,
            }
        )

        return data
    },
}

export default cardApi
