import React from 'react'

import './TelegramChannelRedirectPage.scss'
import QrTgCode from '../../assets/images/QRCode.png'

export default function TelegramChannelRedirectPage() {
    return (
        <div className="TelegramChannelRedirectPage">
            <div className="container">
                <div className="TelegramChannelRedirectPage-wrapper">
                    <h1>Откройте в Telegram</h1>
                    <div className="tg-qr-code">
                        <img src={QrTgCode} alt="QrTgCode" />
                    </div>
                    <a
                        href={`https://t.me/${process.env.REACT_APP_CHANNEL_NAME}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        @{process.env.REACT_APP_CHANNEL_NAME}
                    </a>
                </div>
            </div>
        </div>
    )
}
