import Axios from 'axios'
// import { getToken } from './token'

const API_URL = process.env.REACT_APP_API_URL
const formMethods = ['post', 'put', 'delete']

const axios = Axios.create({
    withCredentials: true,
    baseURL: API_URL,
})

function buildFormData(formData, data, parentKey) {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
        Object.keys(data).forEach((key) => {
            buildFormData(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
            )
        })
    } else if (data !== undefined) {
        formData.append(parentKey, data)
    }
}

axios.interceptors.request.use(
    (request) => {
        if (formMethods.includes(String(request.method))) {
            const formData = new FormData()
            const { data } = request

            if (data) {
                Object.keys(data).forEach((key) => {
                    if (Array.isArray(data[key])) {
                        data[key].forEach((item) => {
                            formData.append(`${key}[]`, item)
                        })
                    } else if (
                        typeof data[key] === 'object' &&
                        data[key] !== null
                    ) {
                        if (data[key] instanceof File) {
                            formData.append(key, data[key])
                        } else {
                            buildFormData(formData, data[key], key)
                        }
                    } else {
                        formData.append(key, data[key])
                    }
                })
                request.data = formData
            }
        }

        return request
    },
    (error) => Promise.reject(error)
)

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error)
    }
)

export default axios
