import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import './index.css'
import './styles/_theme.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider, theme } from 'antd'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <TonConnectUIProvider
                manifestUrl={`${process.env.REACT_APP_APP_URL}/tonconnect-manifest.json`}
                actionsConfiguration={{
                    twaReturnUrl: `https://t.me/${process.env.REACT_APP_APP_NAME}`,
                }}
                language="ru"
            >
                <QueryClientProvider client={queryClient}>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#7f5ff0',
                            },
                            algorithm: theme.darkAlgorithm,
                            components: {
                                Button: {
                                    /* here is your component tokens */
                                },
                            },
                        }}
                    >
                        <App />
                    </ConfigProvider>
                </QueryClientProvider>
            </TonConnectUIProvider>
        </BrowserRouter>
    </React.StrictMode>
)

reportWebVitals()
