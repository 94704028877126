import { useEffect } from 'react'
import { Router } from './router/Router'
import { useTelegram } from './hooks/useTelegram'
import { useUser } from './services'

function App() {
    const { tg, user } = useTelegram()
    const { useGetUser } = useUser()

    const { data } = useGetUser(user?.id)

    useEffect(() => {
        tg.ready()
        tg.setHeaderColor('#28273a')
        tg.setBackgroundColor('#28273a')
        tg?.expand()
        tg.enableClosingConfirmation()
    }, [])

    return (
        <Router
            user={data || {}}
            chatId={user?.id || localStorage.getItem('jovid_chat_id')}
        />
    )
}

export default App
