import axios from '../../utils/axios'
import { USER_KEY } from './userConstants'

const API_KEY = process.env.REACT_APP_API_KEY
const LOCAL_CHAT_ID = localStorage.getItem('jovid_chat_id')

const userApi = {
    async checkSubscription(chatId) {
        const { data } = await axios.post(
            `${USER_KEY.subscription}/?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`
        )

        return data
    },

    async generateKentId(chatId) {
        const { data } = await axios.post(
            `${USER_KEY.generateKentId}/?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`
        )

        return data
    },

    async getReferredUsers(chatId) {
        const { data } = await axios.get(
            `${USER_KEY.getReferredUsers}/?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`
        )

        return data
    },

    async getUser(chatId) {
        const { data } = await axios.get(
            `${USER_KEY.getUser}/?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`
        )

        return data
    },

    async connectUserAddress({ chatId, address }) {
        const { data } = await axios.post(
            `${USER_KEY.connectAddress}/?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`,
            {
                address,
            }
        )

        return data
    },

    async getUserWallet(chatId) {
        const { data } = await axios.get(
            `${USER_KEY.wallet}/?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`
        )

        return data
    },

    async getLiderboard(chatId) {
        const { data } = await axios.get(
            `${USER_KEY.liderboard}/?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`
        )

        return data
    },
}

export default userApi
